import React from "react";
import { Collapse } from "antd";
import { BtnAtras } from "../navegacion/btn_atras";
const { Panel } = Collapse;

export const CompTermAndCondiciones = () => {
  const onChange = (key) => {
    console.log(key);
  };

  return (
    <>
      <BtnAtras titulo={"Términos y Condiciones"} />
      <div className="conteiner-fluid p-5">
        <p className="respuesta" style={{ textAlign: "justify" }}>
          <h1 className="subtitulo-empresas">Bienvenido a Owl Track</h1>
          <span>
            {" "}
            Estos Términos y Condiciones ("Términos") rigen el uso de nuestro
            servicio, proporcionado por Fase Cuatro S.A.S. ("nosotros" o
            "nuestro"). <br></br> Al utilizar nuestro software web, usted
            ("usuario" o "usted") acepta estos Términos en su totalidad. Si no
            está de acuerdo con estos Términos, debe dejar de utilizar nuestro
            servicio de inmediato.
          </span>
          <hr></hr>
          <h1 className="subtitulo-empresas">1. Descripción del Servicio</h1>
          <span>
            {" "}
            Owl Track es una solución web que proporciona una herramienta para
            el registro de la huella de carbono, así como la generación de un
            reporte o certificado adecuado al plan que escoja el usuario. Este
            software no guarda ni almacena datos confidenciales de los usuarios,
            y se compromete a proteger la privacidad de todos los usuarios y sus
            datos.
          </span>
          <hr></hr>
          <h1 className="subtitulo-empresas">2. Cuentas de Usuario</h1>
          <h3 className="subtitulo-terminos">2.1 Registro:</h3>
          <span>
            Para acceder a ciertas funciones de nuestro software, es posible que
            deba crear una cuenta. Usted es responsable de mantener la
            confidencialidad de su cuenta y contraseña, y de todas las
            actividades que ocurran bajo su cuenta.
          </span>
          <h3 className="subtitulo-terminos mt-2">
            2.2 Veracidad de la Información:
          </h3>{" "}
          <span>
            {" "}
            Usted se compromete a proporcionar información precisa, completa y
            actualizada al registrarse y utilizar nuestro servicio.
          </span>
          <hr></hr>
          <h1 className="subtitulo-empresas">
            3. Planes de Suscripción y Pagos
          </h1>
          <h3 className="subtitulo-terminos">3.1 Planes de Suscripción:</h3>{" "}
          <span>
            Ofrecemos varios planes de suscripción que pueden incluir funciones
            adicionales. Los detalles y precios de estos planes están
            disponibles en nuestra web wwww.owl-track.com/planes.
          </span>
          <h3 className="subtitulo-terminos mt-2">3.2 Pagos:</h3>
          <span>
            Las tarifas de suscripción se deben pagar de acuerdo con los
            términos establecidos en el plan que elija. Los pagos se efectuarán
            de forma automática a través del método de pago seleccionado al
            registrarse.
          </span>
          <h3 className="subtitulo-terminos mt-2">3.3 Impagos:</h3>
          <span>
            Si no se realiza el pago en la fecha de vencimiento, nos reservamos
            el derecho de suspender o cancelar su acceso al servicio hasta que
            se regularicen los pagos pendientes.
          </span>
          <hr></hr>
          <h1 className="subtitulo-empresas">4. Cancelación y Terminación</h1>
          <h3 className="subtitulo-terminos">
            4.1 Cancelación por parte del Usuario:
          </h3>{" "}
          <span>
            {" "}
            Puede cancelar su suscripción en cualquier momento a través de su
            cuenta. La cancelación surtirá efecto al final del período de
            facturación actual. No se otorgarán reembolsos por los pagos
            realizados.
          </span>
          <h3 className="subtitulo-terminos mt-2">
            4.2 Terminación por Impago:
          </h3>
          <span>
            {" "}
            Si su cuenta queda pendiente de pago por más de 10 días, podremos
            cancelar su suscripción y su acceso al servicio sin previo aviso.
          </span>
          <h3 className="subtitulo-terminos mt-2">
            4.3 Derecho a Terminar el Servicio:
          </h3>{" "}
          <span>
            Nos reservamos el derecho de terminar su acceso al servicio en
            cualquier momento por cualquier motivo, incluyendo pero no limitado
            a, la violación de estos Términos.
          </span>
          <hr></hr>
          <h1 className="subtitulo-empresas">5. Uso Aceptable</h1>
          <h3 className="subtitulo-terminos">
            5.1 Prohibiciones Generales:
          </h3>{" "}
          <span>
            {" "}
            Usted acepta no usar el servicio para ningún propósito ilegal o no
            autorizado. Esto incluye, pero no se limita a, el uso del servicio
            para transmitir cualquier contenido que sea ilegal, ofensivo o que
            infrinja los derechos de cualquier tercero.
          </span>
          <h3 className="subtitulo-terminos mt-2">
            5.2 Derechos de Propiedad Intelectual:{" "}
          </h3>
          <span>
            {" "}
            Todo el contenido del servicio, incluyendo texto, gráficos, logos y
            software, es propiedad de Fase Cuatro S.A.S. o sus licenciantes y
            está protegido por las leyes de derechos de autor y otras leyes de
            propiedad intelectual.
          </span>
          <hr></hr>
          <h1 className="subtitulo-empresas">
            6. Limitación de Responsabilidad
          </h1>
          <span>
            Fase Cuatro S.A.S. no será responsable por ningún daño indirecto,
            incidental, especial, consecuente o punitivo, incluyendo pero no
            limitado a, pérdida de ingresos, datos o uso, que surja de o en
            conexión con el uso del servicio.
          </span>
          <hr></hr>
          <h1 className="subtitulo-empresas">
            7. Modificaciones a los Términos
          </h1>
          <span>
            {" "}
            Nos reservamos el derecho de modificar estos Términos en cualquier
            momento. Cualquier cambio entrará en vigor inmediatamente después de
            su publicación en nuestro sitio web. Su uso continuado del servicio
            después de la publicación de los términos modificados constituye su
            aceptación de los mismos.
          </span>
          <hr></hr>
          <h1 className="subtitulo-empresas">8. Ley Aplicable</h1>
          <span>
            {" "}
            Estos Términos se regirán e interpretarán de acuerdo con las leyes
            de Argentina, sin dar efecto a sus conflictos de leyes.
          </span>
          <hr></hr>
          <h1 className="subtitulo-empresas">9. Contacto</h1>
          <span>
            {" "}
            Si tiene alguna pregunta sobre estos Términos, por favor contáctenos
            en info@owl-track.com
          </span>
        </p>
      </div>
    </>
  );
};
