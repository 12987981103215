import { Tooltip, Col, Row } from "antd";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  traerDatos,
  CheckExpiracion,
  renewSession,
  HeadersAuth,
} from "../../funciones_utiles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";
const MySwal = withReactContent(Swal);

const URI_EMPRESAS =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/empresas_por_usuario";

export const CompConfigEmpresa = ({ sesion }) => {
  const [t] = useTranslation("global");
  const [inputValue] = useState("");
  const [filteredList, setFilteredList] = useState();
  const [infoEmpresa, setInfoEmpresa] = useState([]);
  const [infoSedes, setInfoSedes] = useState([]);
  const [configActivos, setConfigActivos] = useState({
    empresaActiva: sesion.empresaActiva,
    sedeActiva: sesion.sedeActiva,
  });

  useEffect(() => {
    (async () => {
      setInfoEmpresa(await traerDatos(URI_EMPRESAS));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setInfoSedes(
        await traerDatos(
          process.env.REACT_APP_DOMINIO +
            process.env.REACT_APP_PUERTO +
            "/sedes_por_empresa?id=" +
            configActivos.empresaActiva +
            "&limit=" +
            sesion.maxSedes
        )
      );
    })();
  }, [configActivos.empresaActiva, sesion.maxSedes]);

  useEffect(() => {
    if (inputValue) {
      setFilteredList(
        infoEmpresa.filter(
          (item) =>
            item.razon_social
              .toString()
              .toLowerCase()
              .indexOf(inputValue.toString().toLowerCase()) > -1
        )
      );
    } else {
      setFilteredList(infoEmpresa);
    }
  }, [inputValue, infoEmpresa]);

  const handleChange = async (e) => {
    const { id, value } = e.target;
    setConfigActivos({
      ...configActivos,
      [id]: value,
    });
  };

  const guardar = async (e) => {
    e.preventDefault();

    if (configActivos.sedeActiva !== "") {
      const URI_UPDATE_ACTIVAS =
        process.env.REACT_APP_DOMINIO +
        process.env.REACT_APP_PUERTO +
        "/update_activas";
      const registro = await axios.post(
        URI_UPDATE_ACTIVAS,
        configActivos,
        HeadersAuth
      );
      if (registro.data.errors) {
        const errores = registro.data.errors;
        const msgError = errores.map((error) => "<pre>" + error.msg + "</pre>");
        MySwal.fire({
          html: `${msgError}`,
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else {
        if (registro.data.code === 409) {
          CheckExpiracion();
        } else {
          if (registro.data.code === 200) {
            await renewSession();
            MySwal.fire({
              text: t("mensajes.mensaje35"),
              imageUrl: "img/img_activada.png",
              imageHeight: 65,
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });

            // setConfigActivos({ ...configActivos });
          }
        }
      }
    } else {
      MySwal.fire({
        text: t("mensajes.mensaje36"),
        imageUrl: "img/lechuza-triste.png",
        imageHeight: 55,
        confirmButtonText: "Ok",
      });
    }
  };

  return (
    <>
      <div className="row ">
        <form
          onSubmit={guardar}
          className="d-flex align-items-center justify-content-between w-100 flex-row mx-2"
        >
          {parseInt(sesion.maxSedes) > 1 && infoSedes.length > 1 ? (
            <>
              <label className=" texto-explicacion-sedes" >
                {t("head.labelSede")}
                <select
                    className="form-select-sm mx-2 form-select-sedes"
                    style={{width:"7em", background:"transparent"}}
                  id="sedeActiva"
                  onChange={handleChange}
                  value={configActivos.sedeActiva || ""}
                >
                  {infoSedes.map((option) => (
                    <option key={option.id_sede} value={option.id_sede}>
                      {option.nombre}
                    </option>
                  ))}
                </select>
              </label>
            </>
          ) : (
            <div className="d-flex align-items-center ">
              <h2 className="texto-explicacion-sedes mt-2">{t("head.labelSede")}</h2>
              <div className="form-select form-select-sm form-select-sedes">
                {infoSedes.length === 0 ? "" : infoSedes[0].nombre}
              </div>
            </div>
          )}

          {parseInt(sesion.maxSedes) > 1 && infoSedes.length > 1 ? (
            <button className="boton-agregar-empresa ">
              {t("botones.cambiarSede")}
            </button>
          ) : null}
        </form>
      </div>
    </>
  );
};
