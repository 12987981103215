import { Tooltip } from "antd";
import axios from "axios";
import { useState, useMemo, useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  traerDatos,
  HeadersAuth,
  traerDatosObjeto,
  CheckExpiracion,
} from "../../funciones_utiles";
import {
  validarTelefono,
  validarTextoNumerico,
  validarSelector,
} from "../../validaciones";
import { useTranslation } from "react-i18next";
const MySwal = withReactContent(Swal);

const URI_PAISES =
  process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/paises";
const URI_PROVINCIAS =
  process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/provincias";

export const CompDatosSede = ({ id_empresa, id_sede }) => {
  const [t,i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  // console.log("LA EMPRESA ES: " + id_empresa);
  const [opcionesPaises, setOpcionesPaises] = useState([]);
  const [opcionesProvincias, setOpcionesProvincias] = useState([]);
  const [tempDatosEmpresa, setTempDatosEmpresa] = useState({});
  const URI_UPDATE_SEDE =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/update_sede?id=" +
    id_empresa +
    "&ids=" +
    id_sede;
  const URI_DATOS_SEDE =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/datos_sede?id=" +
    id_empresa +
    "&ids=" +
    id_sede;

  var [tooltipVisible, setTooltipVisible] = useState({
    nombre: false,
    pais: false,
    provincia_estado: false,
    ciudad: false,
    domicilio: false,
    telefono: false,
  });
  const [errores, setErrores] = useState({
    nombre: "",
    pais: "",
    provincia_estado: "",
    ciudad: "",
    domicilio: "",
    telefono: "",
  });
  const campos = [
    { name: "nombre", validator: validarTextoNumerico },
    { name: "ciudad", validator: validarTextoNumerico },
    { name: "domicilio", validator: validarTextoNumerico },
    { name: "telefono", validator: validarTelefono },
    { name: "provincia_estado", validator: validarSelector },
    { name: "pais", validator: validarSelector },
  ];

  useMemo(() => {
    (async () => {
      setOpcionesPaises(await traerDatos(URI_PAISES));
    })();
  }, []);
  useEffect(() => {
    (async () => {
      setTempDatosEmpresa(await traerDatosObjeto(URI_DATOS_SEDE));
      console.log(tempDatosEmpresa);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      setOpcionesProvincias(
        await traerDatos(URI_PROVINCIAS + "?id=" + tempDatosEmpresa.pais)
      );
    })();
  }, [tempDatosEmpresa.pais]);

  const handleChange = async (e) => {
    const { id, value } = e.target;
    if (id === "pais") {
      setTempDatosEmpresa({
        ...tempDatosEmpresa,
        provincia_estado: "",
        [id]: value,
      });
    } else {
      setTempDatosEmpresa({
        ...tempDatosEmpresa,
        [id]: value,
      });
    }
    let erroresTemp = { ...errores };
    const validarCampo = async (campo, valor) => {
      const resultadoValidacion = await campo(valor,t);
      return {
        mensaje: resultadoValidacion.mensaje,
        valido: resultadoValidacion.valido,
      };
    };
    const campoActual = campos.find((campo) => campo.name === id);
    if (campoActual) {
      const mira = await validarCampo(campoActual.validator, value);
      erroresTemp[id] = mira.mensaje;
      setTooltipVisible((prevState) => ({
        ...prevState,
        [id]: !mira.valido,
      }));
      setTimeout(() => {
        setTooltipVisible((prevState) => ({
          ...prevState,
          [id]: false,
        }));
      }, 3000);
    }
    setErrores(erroresTemp);
  };

  const guardar = async (e) => {
    e.preventDefault();
    // console.log(tempDatosEmpresa)
    let hayErrores = false;
    const erroresTemp = {};
    for (const campo of campos) {
      const valorCampo = String(tempDatosEmpresa[campo.name]);
      const resultadoValidacion = await campo.validator(valorCampo,t);

      if (resultadoValidacion.valido === false) {
        hayErrores = true;
        erroresTemp[campo.name] = resultadoValidacion.mensaje;
        setTooltipVisible((prevState) => ({
          ...prevState,
          [campo.name]: !resultadoValidacion.valido,
        }));
        setTimeout(() => {
          setTooltipVisible((prevState) => ({
            ...prevState,
            [campo.name]: false,
          }));
        }, 3000);
      }
    }

    if (hayErrores) {
      console.log(hayErrores);
      setErrores(erroresTemp);
      return;
    } else {
      const registro = await axios.post(
        URI_UPDATE_SEDE,
        tempDatosEmpresa,
        HeadersAuth
      );
      if (registro.data.errors) {
        const errores = registro.data.errors;
        // console.log(registro.data.errors)
        const msgError = errores.map((error) => {
          return "<pre>" + (currentLanguage === "es" ? error.msg.ES : error.msg.EN) + "</pre>";
        });
        MySwal.fire({
          html: `${msgError}`,
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else {
        if (registro.data.code === 409) {
          CheckExpiracion();
        } else {
          if (registro.data.code === 200) {
            MySwal.fire({
              text: t("mensajes.mensaje7"),
              imageUrl: "img/guardado-exitoso.svg",
              imageHeight: 65,
              confirmButtonText: "Ok",
            });
          }
        }
      }
    }
  };
  return (
    <>
      <form onSubmit={guardar}>
        <div className="row g-3 justify-content-center align-items-center ms-4 me-4">
          <div className="col-10 col-xl-5">
            <label className="select_lista"> {t("empresa.datosSede.nombreSede")}</label>
            <Tooltip
              title={!!errores.nombre && <span>{errores.nombre}</span>}
              open={tooltipVisible.nombre}
              color="#1CA6AF"
            >
              <input
                className="form-control"
                type="text"
                placeholder={t("registro.nombre")}
                id="nombre"
                value={tempDatosEmpresa.nombre || ""}
                onChange={handleChange}
              />
            </Tooltip>
          </div>
          <div className="col-10 col-xl-5">
            <label className="select_lista">{t("empresa.datosSede.pais")}</label>
            <Tooltip
              title={!!errores.pais && <span>{errores.pais}</span>}
              open={tooltipVisible.pais}
              color="#1CA6AF"
            >
              <select
                className="form-select"
                id="pais"
                onChange={handleChange}
                value={tempDatosEmpresa.pais || ""}
              >
                <option value="">{t("empresa.datosSede.elejirOpcion")}</option>
                {opcionesPaises.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.paisnombre}
                  </option>
                ))}
              </select>
            </Tooltip>
          </div>
          <div className="col-10 col-xl-5">
            <label className="select_lista">{t("empresa.datosSede.provincia")}</label>
            <Tooltip
              title={
                !!errores.provincia_estado && (
                  <span>{errores.provincia_estado}</span>
                )
              }
              open={tooltipVisible.provincia_estado}
              color="#1CA6AF"
            >
              <select
                className="form-select"
                id="provincia_estado"
                onChange={handleChange}
                value={tempDatosEmpresa.provincia_estado || ""}
              >
                <option value="">{t("empresa.datosSede.elejirOpcion")}</option>
                {opcionesProvincias.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.estadonombre}
                  </option>
                ))}
              </select>
            </Tooltip>
          </div>
          <div className="col-10 col-xl-5">
            <label className="select_lista">{t("empresa.datosSede.ciudad")}</label>
            <Tooltip
              title={!!errores.ciudad && <span>{errores.ciudad}</span>}
              open={tooltipVisible.ciudad}
              color="#1CA6AF"
            >
              <input
                className="form-control"
                type="text"
                placeholder={t("empresa.datosSede.ciudad")}
                id="ciudad"
                value={tempDatosEmpresa.ciudad || ""}
                onChange={handleChange}
              />
            </Tooltip>
          </div>
          <div className="col-10 col-xl-5">
            <label className="select_lista">{t("empresa.datosSede.domicilio")}</label>
            <Tooltip
              title={!!errores.domicilio && <span>{errores.domicilio}</span>}
              open={tooltipVisible.domicilio}
              color="#1CA6AF"
            >
              <input
                className="form-control"
                type="text"
                placeholder={t("empresa.datosSede.domicilio")}
                id="domicilio"
                value={tempDatosEmpresa.domicilio || ""}
                onChange={handleChange}
              />
            </Tooltip>
          </div>
          <div className="col-10 col-xl-5">
            <label className="select_lista">{t("empresa.datosSede.telefono")}</label>
            <Tooltip
              title={!!errores.telefono && <span>{errores.telefono}</span>}
              open={tooltipVisible.telefono}
              color="#1CA6AF"
            >
              <input
                className="form-control"
                type="number"
                placeholder={t("tooltip.telefono")}
                id="telefono"
                value={tempDatosEmpresa.telefono || ""}
                onChange={handleChange}
              />
            </Tooltip>
          </div>
          <div className="d-flex justify-content-center">
            <button className="boton-agregar-empresa" type="submit">
              <span className="label-boton">{t("botones.guardarSede")}</span>
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
