import React, { useState } from "react";
import { Tabs, Button, Row, Col } from "antd";
import { TfiLightBulb } from "react-icons/tfi";
import { BsAirplaneEngines, BsFuelPump, BsTruck } from "react-icons/bs";
import { BtnAtras } from "../navegacion/btn_atras";
import {
  FaLeaf,
  FaLightbulb,
  FaSyncAlt,
  FaSeedling,
  FaClipboardCheck,
  FaRecycle,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { BsEvFrontFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { HeadersAuth, CheckExpiracion } from "../funciones_utiles";

const MySwal = withReactContent(Swal);

export const CompMitigacionPersonal = ({ sesion }) => {
  const [t] = useTranslation("global");
  const [enviandoMensaje, setEnviandoMensaje] = useState(false);
  const URI_PARTNER =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/enviar_mensaje_partner";

  const enviarMailPaneles = async (e) => {
    e.preventDefault();
    setEnviandoMensaje(true);
    const registro = await axios.post(
      URI_PARTNER + "?id=" + sesion.empresaActiva + "&idp=" + 1,
      HeadersAuth
    );

    if (registro.data.code === 409) {
      setEnviandoMensaje(false);
      CheckExpiracion();
    } else if (registro.data.code === 406) {
      setEnviandoMensaje(false);
      MySwal.fire({
        text: t("mensajes.mensaje30"),
        imageUrl: "img/lechuza-triste.png",
        imageHeight: 55,
        confirmButtonText: "Ok",
      });
    } else if (registro.data.code === 200) {
      setEnviandoMensaje(false);
      MySwal.fire({
        text: t("mensajes.mensaje31"),
        imageUrl: "img/guardado-exitoso.svg",
        imageHeight: 65,
        confirmButtonText: "Ok",
      });
    }
  };

  return (
      <div className="row g-3 d-flex justify-content-center ms-3 me-3 p-2">
      <BtnAtras titulo={"Medidas mitigadoras"} />
      <div className="p-3">
        <Row>
          <Col>
            <p>
              <FaLeaf className="icono-medidas" /> {t("medidas3.titulo1")} 
            </p>
          </Col>
        </Row>
        <hr></hr>
        <h1 className="subtitulo-empresas-mitigadoras"><FaRecycle className="icono-medidas"/>  {t("medidas3.consumo.titulo")} </h1>
        <p>
          {" "}
          {t("medidas3.consumo.parrafo1")} 
        </p>
        <h2 className="span-empresas-mitigadoras">
        {t("medidas3.consumo.subtitulo1")} 
        </h2>
        <p>
          {" "}
          {t("medidas3.consumo.parrafo2")} {" "}
        </p>
        <h2 className="span-empresas-mitigadoras">
        {t("medidas3.consumo.subtitulo2")} 
        </h2>
        <p>
        {t("medidas3.consumo.parrafo3")}{" "}
        </p>
        
        <h2 className="span-empresas-mitigadoras">{t("medidas3.consumo.subtitulo3")} </h2>
          <p>
          {t("medidas3.consumo.parrafo4")}{" "}
        </p>
        <h1 className="subtitulo-empresas-mitigadoras"><BsEvFrontFill className="icono-medidas"/> {t("medidas3.movilidad.titulo")}</h1>
        <p>
        {t("medidas3.consumo.parrafo1")}
        </p>
        <h2 className="span-empresas-mitigadoras">
        {t("medidas3.consumo.subtitulo1")}
        </h2>
        <p>
          {t("medidas3.consumo.parrafo2")}
        </p>
        <h2 className="span-empresas-mitigadoras"> {t("medidas3.consumo.subtitulo2")}</h2>
        <p>
        {t("medidas3.consumo.parrafo3")}
        </p>
        <h1 className="subtitulo-empresas-mitigadoras"><FaLightbulb className="icono-medidas"/> {t("medidas3.energia.titulo")}</h1>
        <h2 className="span-empresas-mitigadoras">
        {t("medidas3.energia.subtitulo1")}
        </h2>
        <p>
        {t("medidas3.energia.parrafo1")}
        </p>{" "}
        <h2 className="span-empresas-mitigadoras">
        {t("medidas3.energia.subtitulo2")}{" "}
        </h2>
        <p>
        {t("medidas3.energia.parrafo2")}
        </p>
        <h2 className="span-empresas-mitigadoras">
        {t("medidas3.energia.subtitulo3")}{" "}
        </h2>
        <p>
        {t("medidas3.energia.parrafo3")}
        </p>
      </div>
    </div>
  );
};

export default CompMitigacionPersonal;
